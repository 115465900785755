import { Link } from "react-router-dom";

const Footer = () => {
  // Dynamically get the current year
  const currentYear = new Date().getFullYear();
  
  return (
    <>
      <footer className="footer">
        <div className="wrapper">
          <div className="footer_top">
            <div className="cont_col cont_large">
              <Link to="/" className="footer_logo">
                <img
                  src="/common/images/wax-block-footer-logo.png"
                  alt="wax-block-footer-logo"
                />
              </Link>
              <p>Mapping all of the activity on the WAX Blockchain</p>
              <ul className="social_icons">
                <li>
                  <a
                    className="twitter"
                    href="https://twitter.com/WAX_io"
                    target="_blank"
                  >
                    <i className="fa fa-twitter" aria-hidden="true" />
                  </a>
                </li>
                {/* <li>
                  <a className="facebook" href="#" target="_blank">
                    <i className="fa fa-facebook" aria-hidden="true" />
                  </a>
                </li> */}
                {/* <li><a href="#" target="_blank">cc</a></li>
          <li><a href="#" target="_blank">cc</a></li> */}
              </ul>
            </div>
            {/* <div className="cont_col">
              <h3>Marketplace</h3>
              <ul className="nav">
                <li>
                  <a href="#" target="_blank">
                    All NFTs
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    New
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    Verified
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    Games
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    Collectibles
                  </a>
                </li>
              </ul>
            </div> */}
            {/* <div className="cont_col">
              <h3>My Account</h3>
              <ul className="nav">
                <li>
                  <a href="#" target="_blank">
                    Sign-In
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    Support
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    Settings
                  </a>
                </li>
              </ul>
            </div> */}
            <div className="cont_col">
              <h3>Resources</h3>
              <ul className="nav">
                <li>
                  <a href="https://wdny.io/news/" target="_blank">
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="https://discord.com/invite/dJtPetMdfb"
                    target="_blank"
                  >
                    Discord
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/worldwide-asset-exchange/whitepaper"
                    target="_blank"
                  >
                    Whitepaper
                  </a>
                </li>
              </ul>
            </div>
            <div className="cont_col">
              <h3>Company</h3>
              <ul className="nav">
                <li>
                  <a href="https://www.wax.io/" target="_blank">
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.wax.io/contact"
                    target="_blank"
                  >
                    Connect with WAX
                  </a>
                </li>
              </ul>
            </div>
            <div className="cont_col">
              <h3>WAX Explorers</h3>
              <ul className="nav">
                <li>
                  <a href="https://waxblock.io" target="_blank">
                    WAX Mainnet
                  </a>
                </li>
                <li>
                  <a href="https://testnet.waxblock.io" target="_blank">
                    WAX Testnet
                  </a>
                </li>
                <li>
                  <a href="https://local.waxblock.io" target="_blank">
                    Local Testnet
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer_bottom">
            <div className="copyright">
              <p>© {currentYear} WAX, Worldwide Asset eXchange™ All rights reserved</p>
            </div>
            <ul className="terms">
              <li>
                <a href="https://on.wax.io/privacy/">Privacy Policy</a>
              </li>
              <li>
                <a href="https://on.wax.io/tos/">Terms</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
